<template>
  <v-app id="unauthorized">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-form>
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark >
                  <v-toolbar-title>
                    <v-icon large left>fa-lock</v-icon>
                    <span class="title font-weight-light">{{translate('Unauthorized')}}</span>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="signOut()">
                    <v-icon>exit_to_app</v-icon>
                    {{translate('Sign out')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'translate']),
  },
  methods: {
    ...mapActions(['deauthenticate']),
    signOut: async function () {
      await this.deauthenticate()
      window.location = '/'
      // this.$router.push('/')
    }
  }
}
</script>
